var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      attrs: { title: _vm.$t("lbl_detail_x", { x: _vm.$t("lbl_work_order") }) }
    },
    [
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
          _c(
            "a-form-model",
            {
              ref: "formModel",
              attrs: {
                "label-align": "left",
                "wrapper-col": { span: 12 },
                "label-col": { span: 7 }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_wo_number"),
                            prop: "documentNumber"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              name: "work-order_wo-number",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.documentNumber,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "documentNumber",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.documentNumber"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_branch"),
                            prop: "branchWarehouse"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { name: "work-order_branch", disabled: "" },
                            model: {
                              value: _vm.store.detailDraft.branchWarehouse,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "branchWarehouse",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.branchWarehouse"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_wo_date"),
                            prop: "workOrderDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              name: "work-order_wo-date",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.workOrderDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "workOrderDate",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.workOrderDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_schedule_order"),
                            prop: "scheduleOrder"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              "disabled-date": _vm.useDisabledBackDate,
                              name: "work-order_schedule-order",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.scheduleOrder,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "scheduleOrder",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.scheduleOrder"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_mechanic_name"),
                            prop: "mechanicName"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              name: "work-order_mechanic",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.mechanicName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "mechanicName",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.mechanicName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            prop: "description",
                            "label-align": "right"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: {
                                      value: _vm.store.detailDraft.description
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              name: "work-order_description",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.description,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_name"),
                            prop: "customerName",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              name: "work-order_customer-name",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.customerName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "customerName",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.customerName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_address"),
                            prop: "customerAddress",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              name: "work-order_customer-address",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.customerAddress,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDraft,
                                  "customerAddress",
                                  $$v
                                )
                              },
                              expression: "store.detailDraft.customerAddress"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_customer_pic_name"),
                            prop: "picName",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              name: "work-order_customer-pic-name",
                              "allow-clear": "",
                              disabled: ""
                            },
                            model: {
                              value: _vm.store.detailDraft.picName,
                              callback: function($$v) {
                                _vm.$set(_vm.store.detailDraft, "picName", $$v)
                              },
                              expression: "store.detailDraft.picName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_unit")) + " ")]),
          _c("a-table", {
            attrs: {
              "data-source": _vm.store.detailDraft.units,
              columns: _vm.unitColumns,
              "row-key": function(r, i) {
                return i
              },
              pagination: false,
              size: "small",
              scroll: { y: 600, x: "calc(100% + 100px)" }
            },
            scopedSlots: _vm._u([
              {
                key: "nullable",
                fn: function(text) {
                  return [_vm._v(" " + _vm._s(text || "-") + " ")]
                }
              },
              {
                key: "index",
                fn: function(text, row, idx) {
                  return [_vm._v(" " + _vm._s(idx + 1) + " ")]
                }
              }
            ])
          }),
          _c("div", { staticClass: "mt-2 text-right" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("lbl_total_items", {
                    total: _vm.store.detailDraft.units.length
                  })
                ) +
                " "
            )
          ])
        ],
        1
      ),
      _c(
        "fieldset",
        [
          _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_part")) + " ")]),
          _c("a-table", {
            attrs: {
              "data-source": _vm.store.detailDraft.spareParts,
              columns: _vm.sparepartColumns,
              pagination: false,
              "row-key": function(r, i) {
                return i
              },
              size: "small",
              scroll: { y: 600, x: "calc(100% + 200px)" }
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(text, row, idx) {
                  return [_vm._v(" " + _vm._s(idx + 1) + " ")]
                }
              },
              {
                key: "number",
                fn: function(text) {
                  return [_vm._v(" " + _vm._s(_vm._f("qty")(text)) + " ")]
                }
              },
              {
                key: "nullable",
                fn: function(text) {
                  return [_vm._v(" " + _vm._s(text || "-") + " ")]
                }
              }
            ])
          }),
          _c("div", { staticClass: "mt-2 text-right" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("lbl_total_items", {
                    total: _vm.store.detailDraft.spareParts.length
                  })
                ) +
                " "
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-5 text-right" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleBack } }, [
                _vm._v(_vm._s(_vm.$t("lbl_back")))
              ]),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.print },
                  on: { click: _vm.handlePrintCsf }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print_csf")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }