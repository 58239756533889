





















































































































































































import { useBlob, useDisabledBackDate, usePrint, useWorkOrder } from "@/hooks";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { State } from "@/store/workOrder.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
  computed: {
    ...mapState({
      store: (st: any) => st.workOrderStore,
    }),
  },
})
export default class DetailPage extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  useDisabledBackDate = useDisabledBackDate;

  store!: State;

  @Prop({ required: false, type: String, default: "" })
  id!: string; // work order secure id

  loading = {
    print: false,
  };

  unitColumns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: 60,
      scopedSlots: { customRender: "index" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_category"),
      dataIndex: "assetCategory",
      // width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_capacity"),
      dataIndex: "capacity",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "unitSpecification",
      // width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_model"),
      dataIndex: "model",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "location",
      // width: 300,
      scopedSlots: { customRender: "nullable" },
    },
  ];

  sparepartColumns = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      width: 60,
      scopedSlots: { customRender: "index" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      width: 100,
      scopedSlots: { customRender: "unitCode" },
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      width: 300,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "productUom",
      width: 100,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_part_location"),
      dataIndex: "productLocation",
      width: 300,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_qty_wo"),
      dataIndex: "qtyWorkOrder",
      width: 100,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_qty_available"),
      dataIndex: "qtyAvailable",
      width: 150,
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_notes"),
      dataIndex: "notes",
      width: 200,
      scopedSlots: { customRender: "nullable" },
    },
  ];

  handleBack(): void {
    this.$router.push({
      name: "logistic.work-order",
      query: this.$route.query,
    });
  }

  async handlePrintCsf(): Promise<void> {
    const { printCsf } = useWorkOrder();
    const { toObjectUrl } = useBlob();
    const { print } = usePrint();
    try {
      this.loading.print = true;
      const res = await printCsf(this.id);
      print(toObjectUrl(res));
    } finally {
      this.loading.print = false;
    }
  }
}
